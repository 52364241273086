<template>
  <ul class="m-goods-columns m-padding">
      <!-- 骨架屏加载开始 -->
      <Skeleton v-if="data.length <= 0 && loading" :columns="columns" />
      <!-- 骨架屏加载结束 -->

      <li v-for="(item, index) in data" :key="index" :class="{'bg': columns != 1}" :style="{width:columns == 1 ? '100%' : 'auto'}">
        <!-- 列表格式为 一行一列 -->
        <router-link :to="{path: `/en/goods/detail/${item.goods_id}`}"
        class="goods-columns-1" v-if="columns == 1">
        <span class="discount" v-if="(100-((item.activity_min_price / item.min_price)*100)).toFixed(0) != 0">{{(100-((item.activity_min_price / item.min_price)*100)).toFixed(0)}}% OFF</span>
          <img v-lazy="$store.state.imgPreUrl + item.pic_image" alt="goods">
          <div class="eleven" v-if="elevenShow"></div>
          <div class="goods-info">
            <p class="van-multi-ellipsis--l2">
              <span class="m-mooby">M</span>
              {{item.name}}
            </p>     
            <div class="goods-price">
              <div>
                <p>
                  <span>$</span><span>{{item.activity_min_price}}</span>
                  <span v-if="item.activity_min_price != item.min_price">${{item.min_price}}</span>
                </p>
              </div>
              <img  @click.prevent="handleCart(item.goods_id, $event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
            </div>
          </div>
        </router-link>

        <!-- 列表格式为 一行二列 -->
        <div v-else-if="columns == 2" @click="handleVisit(item.id)">
          <router-link :to="{path: `/en/goods/detail/${item.goods_id}`}"
          class="goods-columns-2" >
          <span class="discount" v-if="(100-((item.activity_min_price / item.min_price)*100)).toFixed(0) != 0">{{(100-((item.activity_min_price / item.min_price)*100)).toFixed(0)}}% OFF</span>
            <img v-lazy="$store.state.imgPreUrl + item.pic_image" alt="goods">
            <div class="eleven" v-if="elevenShow"></div>
              <p class="van-multi-ellipsis--l2">
                <span class="m-mooby">M</span>
                <span>{{item.name}}</span>
              </p>
              <div class="goods-price">
                <div> 
                  <p>
                    <span>$</span><span>{{item.activity_min_price}}</span>
                    <span v-if="item.activity_min_price != item.min_price">${{item.min_price}}</span>
                  </p>
                </div>
                <img v-if="cart" @click.prevent="handleCart(item.goods_id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
              </div>
          </router-link>
        </div>

        <!-- 列表格式为 一行三列 -->
        <router-link :to="{path: `/en/goods/detail/${item.goods_id}`}"
        class="goods-columns-3" v-else>
            <span class="discount" v-if="(100-((item.activity_min_price / item.min_price)*100)).toFixed(0) != 0">{{(100-((item.activity_min_price / item.min_price)*100)).toFixed(0)}}% OFF</span>
            <img v-lazy="$store.state.imgPreUrl + item.pic_image" alt="goods">
            <div class="eleven" v-if="elevenShow"></div>
            <p class="van-multi-ellipsis--l2">
              <span class="m-mooby">M</span>
              <span>{{item.name}}</span>
            </p>
            <div class="goods-price">
              <div> 
                <p>
                  <span>$</span><span>{{item.activity_min_price}}</span>
                </p>
                <p v-if="item.activity_min_price != item.min_price">${{item.min_price}}</p>
              </div>
              <img v-if="cart" @click.prevent="handleCart(item.goods_id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
            </div>
        </router-link>
            
      </li>
       
      <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
      <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
  </ul>
</template>

<script>
import Skeleton from '@/components/en/skeleton'
export default {
  name:'GoodsColumns',
  components: { Skeleton },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    columns: {
      type: Number,
      default: 2,
    },
    priceLine: {
      type: Boolean,
      default: true
    },
    cart: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    night:{
      type: Number,
    }
  },
  data() {
    return {
      preList: ['/zh/presale', '/zh/subsale'],
      elevenShow: true // 圣诞节活动边框显示
    }
  },
  methods:{
    handleCart(goods,e){
      this.$emit('cartClick',goods,e)
    },
    handleVisit(id) {
      if(this.night == 1) {
        this.$emit('visitClick',id)
      }
      
    }
  },
  created() {
    
  }
}
</script>

<style lang="less" scoped>
.m-goods-columns {
 width: 100%;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
 position: relative;
 z-index: 9;
 .bg {
   margin-bottom: 16px;
   background: #fff;
   border-radius: 5px;
 }
 .goods-columns-1 {
   background: #fff;
   width: 100%;
   display: flex;
   box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
   border-radius:5px;
   margin-bottom: 12px;
   background: #fff;
   position: relative;
   .discount {
     display: block;
     padding: 0 3px;
     height:18px;
     position: absolute;
     top:8px;
     left:0;
     font-size: 12px;
     background-color: #EF4A29;
     text-align: center;
     line-height: 18px;
     color:#fff;
     border-radius: 0px 2px 2px 0px;
   }
   .eleven {
     position: absolute;
     top: 0;
     left: 0;
     width: 126px;
     height: 126px;
     border-radius:5px 0 0px 5px;
     background: url('../../assets/index/eleven_en.png') no-repeat center;
     background-size: 100% 100%;
   }
   &>img {
     width: 126px;
     height: 126px;
     border-radius:5px 0 0px 5px;
     margin-right: 11px;
   }
   .goods-info {
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     flex: 1;
     padding-top: 10px;
     &>p {
      font-size: 14px;
      letter-spacing: 0.31px;
      line-height: 20px;
      font-weight: 400;
      height: 40px;
      color: #333;
      padding-right: 10px;
     }
   }
   .goods-price {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin:16px 0  6px 0;
     font-size: 12px;
     &>div {
       display: flex;
       align-items: center;
       &>p:nth-of-type(1){
          color: #ED2A24;
          margin-right: 4px;
          &>span:nth-of-type(2){
            font-size: 18px;
          }
          &>span:nth-of-type(3){
            margin-top: 2px;
            margin-left:5px;
            font-size: 10px;
            color: #999999;
            text-decoration:line-through;
          }
        }
     }
     &>img {
        width: 40px;
        height: 40px;
      }
   }
 }
 .goods-columns-2 {
   background: #fff;
   width: 166px;
  //  box-shadow:0px 10px 15px 0px rgba(217, 217, 217, 0.384);
   border-radius:5px;
   margin-bottom: 16px;
   background: #fff;
   display: inline-block;
   height:100%;
   position: relative;
   .van-multi-ellipsis--l2 {
     min-height: 36px;
   }
   .discount {
     display: block;
     padding: 0 3px;
     height:18px;
     position: absolute;
     top:8px;
     left:0;
     font-size: 12px;
     background-color: #EF4A29;
     text-align: center;
     line-height: 18px;
     color:#fff;
     border-radius: 0px 2px 2px 0px;
   }
   .eleven {
     position: absolute;
     top: 0;
     left: 0;
     width: 166px;
     height: 172px;
     border-radius:5px 5px 0 0;
     background: url('../../assets/index/eleven_en.png') no-repeat center;
     background-size: 100% 100%;
   }
   &>img {
     width: 166px;
     height: 172px;
     border-radius:5px 5px 0px 0px;
   }
   &>p {
     width: 166px;
     font-size: 12px;
     letter-spacing: 0.31px;
     line-height: 18px;
     padding: 0 12px;
     color: #333;
   }
   .goods-price {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin: 6px 0  0 0;
     font-size: 12px;
     height:24px;
     padding: 0 8px 0 12px;
     &>div {
       display: flex;
       flex-direction: column;
       justify-content: center;
       &>p:nth-of-type(1){
          color: #ED2A24;
          &>span:nth-of-type(2){
            font-size: 16px;
          }
          &>span:nth-of-type(3){
            margin-top: 2px;
            margin-left:5px;
            font-size: 10px;
            color: #999999;
            text-decoration:line-through;
          }
        }
     }
     &>img {
        width: 40px;
        height: 40px;
      }
   }
 }
 .goods-columns-3 {
    background: #fff;
    width: 108px;
    box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
    border-radius:5px;
    margin-bottom: 16px;
    position: relative;
   .discount {
     display: block;
     padding: 0 3px;
     height: 18px;
     line-height: 18px;
     text-align: center;
     font-size: 12px;
     position: absolute;
     margin-top: 4px;
     background-color: #EF4A29;
     color:#fff;
     border-radius: 0px 2px 2px 0px;
    }
    .eleven {
     position: absolute;
     top: 0;
     left: 0;
     width: 108px;
     height: 108px;
     border-radius:5px 5px 0 0;
     background: url('../../assets/index/eleven_en.png') no-repeat center;
     background-size: 100% 100%;
   }
    &>img {
      width: 108px;
      height: 108px;
      border-radius:5px 5px 0px 0px;
    }
    &>p {
      width: 108px;
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 18px;
      color: #333;
      padding: 0 2px;
    }
    .goods-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:12px 0  6px 0;
      font-size: 12px;
      padding: 0 2px;
      &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>p:nth-of-type(1){
          color: #ED2A24;
          &>span:nth-of-type(2){
            font-size: 14px;
          }
        }
        &>p:nth-of-type(2){
          margin-top: 2px;
          font-size: 10px;
          color: #999999;
          text-decoration:line-through;
        }
      }
      &>img {
          width: 30px;
          height: 30px;
        }
    }
  }
  .hide-columns {
    height: 0;
    opacity: 0;
    margin: 0;
  }
}
</style>